import { Page } from "@views/client/_layout/page";

export const StatisticsPage = () => {
  return (
    <Page
      title={[
        {
          label: "Statistiques",
        },
      ]}
    >
      Welcome to your statistiques
    </Page>
  );
};
